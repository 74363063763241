 
 

import { Routes, Route } from 'react-router-dom';
import {variables} from './Component/Variables';
 
import { useEffect } from "react"
 

 import Child from './Component/Child';
import Qn_news_story_s from './Component/Qn_news_story_s';

//   
import Home from './Component/Home';
import News_Page from './Component/News_Page';
import ContactUs from './Component/ContactUs';
     

import MostViwed from './Component/MostViwed';
import WhatsnewHome from './Component/WhatsnewHome';
import OurLatest_News from './Component/OurLatest_News';
import FootBall_MostViewed from './Component/FootBall_MostViewed';


 

 
 

   //new In CoinsInsight Pages
    
 import ListMainArticle from './Component/ListMainArticle';
 import MainArticle from './Component/MainArticle';
 import SmallArticle from './Component/SmallArticle';
 import SportList from './Component/SportList';
 import MainSportList from './Component/MainSportList';
 import TrendyPage from './Component/TrendyPage';
 import LatestPage from './Component/LatestPage';
 import PopularPage from './Component/PopularPage';
 import Crypto from './Component/Crypto';
 import Economy from './Component/Economy';
 import PressRelease from './Component/PressRelease';
 import MarketResearch from './Component/MarketResearch';
 import Reports from './Component/Reports';
 import NewFeature from './Component/NewFeature';
 import TrendingNewsCoins from './Component/TrendingNewsCoins';
 import TrendingList from './Component/TrendingList';
 import TrendingList2 from './Component/TrendingList2';
 import EntertaimentList from './Component/EntertaimentList';
 import EntertaimentListEconomy from './Component/EntertaimentListEconomy';
 import EntertaimentListPressRelease from './Component/EntertaimentListPressRelease';
 import EntertaimentListMarket from './Component/EntertaimentListMarket';
 import EntertaimentListReports from './Component/EntertaimentListReports';

 import About from './Component/About';
 import Advertise from './Component/Advertise';
 import Privacy from './Component/Privacy';
 import CoinsContact from './Component/CoinsContact';

 
 
 //
 
 
 

  

export default function App( ) {
   
  return (
    
    
    <div className="App">
  
 

    <Routes>

    <Route path ={ variables.basename + "/CoinsContact"} element={<CoinsContact/>} />
    <Route path ="http://coinsinsight.com/CoinsContact" element={<Privacy/>} />
    <Route path ={   "/CoinsContact"} element={<CoinsContact/>} />



    <Route path ={ variables.basename + "/Privacy"} element={<Privacy/>} />
    <Route path ="http://coinsinsight.com/Privacy" element={<Privacy/>} />
    <Route path ={   "/Privacy"} element={<Privacy/>} />

    <Route path ={ variables.basename + "/Advertise"} element={<Advertise/>} />
    <Route path ="http://coinsinsight.com/Advertise" element={<Advertise/>} />
    <Route path ={   "/Advertise"} element={<Advertise/>} />

    <Route path ={ variables.basename + "/About"} element={<About/>} />
    <Route path ="http://coinsinsight.com/About" element={<About/>} />
    <Route path ={   "/About"} element={<About/>} />
 
    <Route path ={ variables.basename + "/TrendingList2"} element={<TrendingList2/>} />
    <Route path ="http://coinsinsight.com/TrendingList2" element={<TrendingList2/>} />
    <Route path ={   "/TrendingList2"} element={<TrendingList2/>} />
    

    
    <Route path ={ variables.basename + "/WhatsnewHome"} element={<WhatsnewHome/>} /> 
    <Route path ="http://coinsinsight.com/WhatsnewHome" element={<WhatsnewHome/>} />
    <Route path ={   "/WhatsnewHome"} element={<WhatsnewHome/>} />

    
    <Route path ={ variables.basename + "/OurLatest_News"} element={<OurLatest_News/>} /> 
    <Route path ="http://coinsinsight.com/OurLatest_News" element={<OurLatest_News/>} />
    <Route path ={   "/OurLatest_News"} element={<OurLatest_News/>} /> 
    
    
   
    

    <Route path ={ variables.basename + "/FootBall_MostViewed"} element={<FootBall_MostViewed/>} /> 
    <Route path ="http://coinsinsight.com/FootBall_MostViewed" element={<FootBall_MostViewed/>} />
    <Route path ={   "/FootBall_MostViewed"} element={<FootBall_MostViewed/>} /> 
    
    
    <Route path ="*" element={<Home/>} />
    <Route path ="" element={<Home/>} />
    <Route path ="/" element={<Home/>} />
    <Route path ="http://coinsinsight.com" element={<Home/>} />
 
        
      <Route path=  {   "/:storyID/:title"}    element={<News_Page/>} />


         <Route path ={ variables.basename } element={<Home/>} />
  
        <Route path ={ variables.basename + "/Home"} element={<Home/>} />

        <Route path ={ variables.basename + "/News_Page"} element={<News_Page/>} />
        
   
        <Route path ={ variables.basename + "/ContactUs"} element={<ContactUs/>} />

        <Route path ={ variables.basename + "/MostViwed"} element={<MostViwed/>} />

 {/* new */}
 
    
    


    
    <Route path ={ variables.basename + "/ListMainArticle"} element={<ListMainArticle/>} />
    <Route path ="http://coinsinsight.com/ListMainArticle" element={<ListMainArticle/>} />
    <Route path ={   "/ListMainArticle"} element={<ListMainArticle/>} />
 

    <Route path ={ variables.basename + "/MainArticle"} element={<MainArticle/>} />
    <Route path ="http://coinsinsight.com/MainArticle" element={<MainArticle/>} />
    <Route path ={   "/MainArticle"} element={<MainArticle/>} />
    
    <Route path ={ variables.basename + "/SmallArticle"} element={<SmallArticle/>} />
    <Route path ="http://coinsinsight.com/SmallArticle" element={<SmallArticle/>} />
    <Route path ={   "/SmallArticle"} element={<SmallArticle/>} />


    <Route path ={ variables.basename + "/SportList"} element={<SportList/>} />
    <Route path ="http://coinsinsight.com/SportList" element={<SportList/>} />
    <Route path ={   "/SportList"} element={<SportList/>} />

      <Route path ={ variables.basename + "/MainSportList"} element={<MainSportList/>} />
    <Route path ="http://coinsinsight.com/MainSportList" element={<MainSportList/>} />
    <Route path ={   "/MainSportList"} element={<MainSportList/>} />
    

    <Route path ={ variables.basename + "/TrendyPage"} element={<TrendyPage/>} />
    <Route path ="http://coinsinsight.com/TrendyPage" element={<TrendyPage/>} />
    <Route path ={   "/TrendyPage"} element={<TrendyPage/>} />
    
    
    <Route path ={ variables.basename + "/LatestPage"} element={<LatestPage/>} />
    <Route path ="http://coinsinsight.com/LatestPage" element={<LatestPage/>} />
    <Route path ={   "/LatestPage"} element={<LatestPage/>} />
    

    <Route path ={ variables.basename + "/PopularPage"} element={<PopularPage/>} />
    <Route path ="http://coinsinsight.com/PopularPage" element={<PopularPage/>} />
    <Route path ={   "/PopularPage"} element={<PopularPage/>} />
    

    <Route path ={ variables.basename + "/Crypto"} element={<Crypto/>} />
    <Route path ="http://coinsinsight.com/Crypto" element={<Crypto/>} />
    <Route path ={   "/Crypto"} element={<Crypto/>} />
    
    <Route path ={ variables.basename + "/Economy"} element={<Economy/>} />
    <Route path ="http://coinsinsight.com/Economy" element={<Economy/>} />
    <Route path ={   "/Economy"} element={<Economy/>} />
    
    <Route path ={ variables.basename + "/PressRelease"} element={<PressRelease/>} />
    <Route path ="http://coinsinsight.com/PressRelease" element={<PressRelease/>} />
    <Route path ={   "/PressRelease"} element={<PressRelease/>} />

    
    <Route path ={ variables.basename + "/MarketResearch"} element={<MarketResearch/>} />
    <Route path ="http://coinsinsight.com/MarketResearch" element={<MarketResearch/>} />
    <Route path ={   "/MarketResearch"} element={<MarketResearch/>} />

    <Route path ={ variables.basename + "/NewFeature"} element={<NewFeature/>} />
    <Route path ="http://coinsinsight.com/NewFeature" element={<NewFeature/>} />
    <Route path ={   "/NewFeature"} element={<NewFeature/>} />

    <Route path ={ variables.basename + "/Reports"} element={<Reports/>} />
    <Route path ="http://coinsinsight.com/Reports" element={<Reports/>} />
    <Route path ={   "/Reports"} element={<Reports/>} />

 

    <Route path ={ variables.basename + "/TrendingList"} element={<TrendingList/>} />
    <Route path ="http://coinsinsight.com/TrendingList" element={<TrendingList/>} />
    <Route path ={   "/TrendingList"} element={<TrendingList/>} />

    <Route path ={ variables.basename + "/TrendingNewsCoins"} element={<TrendingNewsCoins/>} />
    <Route path ="http://coinsinsight.com/TrendingNewsCoins" element={<TrendingNewsCoins/>} />
    <Route path ={   "/TrendingNewsCoins"} element={<TrendingNewsCoins/>} />

    
    <Route path=  {  "/:storyID/:title"}    element={<News_Page/>} />
 <Route path=  { variables.basename + "/:storyID/:title"}    element={<News_Page/>} />
 <Route path=  { variables.basename + "/news_page/:storyID/:title"}    element={<News_Page/>} />
    
 <Route path ={ variables.basename + "/EntertaimentList"} element={<EntertaimentList/>} />
    <Route path ="http://coinsinsight.com/EntertaimentList" element={<EntertaimentList/>} />
    <Route path ={   "/EntertaimentList"} element={<EntertaimentList/>} />

    <Route path ={ variables.basename + "/EntertaimentListEconomy"} element={<EntertaimentListEconomy/>} />
    <Route path ="http://coinsinsight.com/EntertaimentListEconomy" element={<EntertaimentListEconomy/>} />
    <Route path ={   "/EntertaimentListEconomy"} element={<EntertaimentListEconomy/>} />

    <Route path ={ variables.basename + "/EntertaimentListPressRelease"} element={<EntertaimentListPressRelease/>} />
    <Route path ="http://coinsinsight.com/EntertaimentListPressRelease" element={<EntertaimentListPressRelease/>} />
    <Route path ={   "/EntertaimentListPressRelease"} element={<EntertaimentListPressRelease/>} />
    
    <Route path ={ variables.basename + "/EntertaimentListMarket"} element={<EntertaimentListMarket/>} />
    <Route path ="http://coinsinsight.com/EntertaimentListMarket" element={<EntertaimentListMarket/>} />
    <Route path ={   "/EntertaimentListMarket"} element={<EntertaimentListMarket/>} />

    <Route path ={ variables.basename + "/EntertaimentListReports"} element={<EntertaimentListReports/>} />
    <Route path ="http://coinsinsight.com/EntertaimentListReports" element={<EntertaimentListReports/>} />
    <Route path ={   "/EntertaimentListReports"} element={<EntertaimentListReports/>} />

    

    
    </Routes>
    </div>

    
    
  );
}
