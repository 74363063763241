import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

  

import TrendingNewsCoins from './TrendingNewsCoins.js';
import MostViewed from './MostViwed.js';
import ListMainArticle from './ListMainArticle.js';
import WhatsnewHome from './WhatsnewHome.js';
import SportList from './SportList.js';
import TrendyPage from './TrendyPage.js';
import LatestPage from './LatestPage.js';
import PopularPage from './PopularPage.js';
import NewFeature from './NewFeature.js';

export default function Home() {

  async function refda() {  
    setNisLoading(true);
      //  To Show or Hidden Header And Footer
    document.getElementById('English_Header').hidden = false;
    document.getElementById('English_Footer').hidden = false;
    //
   

  };
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
   
    useEffect(() => {
    setNisLoading(true);
    refda();
    },[] )


           
   
           return (
          <>

 

 
 
 
<section className="all-post-style-2-area mt-3">
        <div className="container custom-container">
          <div className="row">
          <div className="col-lg-8 mb-auto">
         {/* New TrendingNewsCoins */}
         <TrendingNewsCoins/>
         </div>


            <div className="col-lg-4 mb-auto">
              <div className="trending-right-sidebar">
               {/* Social Media Last */}
                <div className="trending-most-view mt-1 ">
                  <div className="section-title">
                    <h3 className="title">Most View</h3>
                  </div>
                </div>
               {/* MostView */}
                <MostViewed/>  

              </div>
              <img className='mt-3 ads-Img' src='./images/ad/ad-2.jpg' alt="ads image " />
            </div>
          </div>
        </div>
      </section>

{/* End First Section  */}


 
<section  className="all-post-style-2-area mt-3">
         
          <NewFeature/>  
    
      </section>
 
 {/* Start Section 2 */}
{/* Main Article And List Conatin Main Article And ListArticle And Small Article */}

  <ListMainArticle/>  
  
{/* End Part of List Article */}




 {/* Part 3 Whats new And Sport .. */}


 <section className="post-style-area">
        <div className="container custom-container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="post-style-tab-post mt-40">
                <div className="tab-btn">
                  <ul className="nav nav-pills colors" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a className="nav-link active bg-warning text-white trendyHome">TRENDY</a>
                    </li>
                
                 
                  </ul>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="post-style-items">
                     
                     
                <TrendyPage/>  
                  
             
                 
                   
                    </div>
                 
                  </div>
                  {/* end trendy */}
            
         
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
           {/* Business & Finance */}
          
              <SportList/>  
            </div>
            <div className="col-lg-4 mb-auto">
           {/* Whats New Here  */}

  <WhatsnewHome/>  
            </div>
          </div>
        </div>
      </section>

 
         </>
        );
    }


  
