import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';
import MostViewed from './MostViwed.js';


export default function CoinsContact() {
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();

    async function refda() {  
     //  To Show or Hidden Header And Footer
     document.getElementById('English_Header').hidden = false;
     document.getElementById('English_Footer').hidden = false;
     //
      setNisLoading(true);

    }



      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


            return (
          <>
 <div>CoinsContact newww</div>
 
         </>
        );
    }


  
