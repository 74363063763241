import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';
import MostViewed from './MostViwed.js';


export default function About() {

    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
     //  To Show or Hidden Header And Footer
     document.getElementById('English_Header').hidden = false;
     document.getElementById('English_Footer').hidden = false;
     //
      setNisLoading(true);

    }

      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )


            return (
          <>

<section className="main-wrapper contact-page" style={{margin: '35px 0', fontWeight: '100 !important'}}>
        <div className="container" style={{paddingLeft: '0px', paddingRight: '0px', fontWeight: '100 !important'}}>
          <div id="ColLeft" className="col-sm-9" style={{width: '72.41%', paddingLeft: '0px', paddingRight: '0px'}}>     
            <br />
            <br />
            <table border={0} cellPadding={0} cellSpacing={0}>
              <tbody><tr>
                  <td valign="middle">
                    <h2>
                      <a href="/mf_about.aspx" style={{fontWeight: 600}}>&nbsp;About CoinsInsight</a></h2>
                    <br />
                  </td>
                </tr>
                <tr>
                  <td align="justify" valign="top">
                    <table width="650px" cellPadding={0} cellSpacing={0} border={0}>
                      <tbody><tr>
                          <td width="650px" style={{padding: '10px'}}>
                            TheCoinsInsight, Inc. (CoinsInsight) is the leading provider
                            of online and wireless financial content and financial technology platforms to leading
                            institutions and consumers around the Arab world. CoinsInsightwas founded in June 2000
                            by a team of serial entrepreneurs with successful track records in the region. The
                            company is a Delaware-based corporation with a wholly owned subsidiary in Amman,
                            Jordan and a fully paid-up capital of $2 million.
                            <br />
                            <br />
                            Our service offering include:
                          </td>
                        </tr>
                        <tr>
                          <td width="650px" style={{padding: '10px'}}>
                            <b>B2C Services:</b> CoinsInsightoffers consumers the following services:
                          </td>
                        </tr>
                        <tr>
                          <td width="650px" style={{padding: '10px'}}>
                            <b>- CoinsInsightMobile services:</b> A wireless SMS-based platform that CoinsInsightoperates
                            with leading GSM operators in the Arab world including Fastlink, Batelco and LibanCell.
                            CoinsInsightMobile provides consumers with real-time financial data feeds in both Arabic
                            and English.
                          </td>
                        </tr>
                        <tr>
                          <td width="650px" style={{padding: '10px'}}>
                            <b>- CoinsInsightPortal: </b>The site [www.CoinsInsight.com] offers regional and global business
                            content in both Arabic and English. It attracts over 340,000 highly targeted visitors
                            on a regular basis. CoinsInsightcontent providers exceed 180. CoinsInsightportal is a
                            credible and reliable reference for thousands of senior executives from key organizations.
                          </td>
                        </tr>
                        <tr>
                          <td style={{padding: '10px'}}>
                            <b>- CoinsInsight e-Newsletter: </b>This is a weekly e-Newsletter that reaches 55,000
                            subscribers. It summarizes major business news and events, market data and research
                            for the Middle East region and the globe.
                          </td>
                        </tr>
                        <tr>
                          <td style={{padding: '10px'}}>
                            <b>- CoinsInsightInvestment Simulation: </b>This unique game enables users to learn online
                            investing techniques without risking any of their money. Users can virtually trade
                            U.S. stocks using very advanced features and participate in contests.
                          </td>
                        </tr>
                        <tr>
                          <td height="20px">
                          </td>
                        </tr>
                        <tr>
                          <td width="650px" style={{padding: '10px'}}>
                            <b>B2B Services:</b> CoinsInsightoffers consumers the following services:
                          </td>
                        </tr>
                        <tr>
                          <td style={{padding: '10px'}}>
                            - <b>CoinsInsightResearch: </b>CoinsInsightResearch provides senior management at financial
                            institutions with research on the latest technologies and management issues.
                          </td>
                        </tr>
                        <tr>
                          <td width="650px" style={{padding: '10px'}}>
                            - <b>CoinsInsightAdvisory Services: </b>CoinsInsighthighly experienced professionals with
                            in-depth knowledge of banking operations, technology, and strategy can assist institutions
                            in:
                            <ol style={{listStyleType: 'disc'}}>
                              <li>Portal strategy, development, deployment, and management</li>
                              <li>Media monitoring</li>
                              <li>Development of overall IT strategy
                                <ol style={{listStyleType: 'square'}}>
                                  <li>Alignment of IT investments with business objectives </li>
                                  <li>IT investment priorities</li>
                                  <li>Overall technology direction</li>
                                </ol>
                              </li>
                              <li>Vendor / Solution identification, evaluation, and selection</li>
                              <li>Vendor relationship management [deal structuring and negotiations]</li>
                              <li>Implementation / project management</li>
                              <li>Assessment of functional and technical specifications for banking solutions</li>
                            </ol>
                          </td></tr>
                      </tbody></table>
                  </td>
                </tr>
                <tr>
                  <td style={{padding: '10px'}}>
                    - <b>Advertising opportunities: </b><br />Institutions can promote their products and
                    services with CoinsInsightvia multiple channels including online banners (standard, skyscrapers,
                    etc.), eye blasters, pop-up windows, email shots, e-Newsletter sponsorships, sponsorships
                    of specialized sections. Clients who have advertised with CoinsInsightinclude Saudi Telecom
                    Co., Emirates Airlines, Saudi Airlines, Hilton, Mercedes Benz, American Express
                    ME, Aramex International, Middle East Economic Digest, Abu Dhabi Commercial Bank,
                    British Airways, Cyberia, Arab National Bank, ACCPAC, Batelco, Gulf International
                    Bank, Emirates Holidays, Volvo, Mashreqbank, Banker Middle East, GCI Financials,
                    Banker ME, Al Rajhi Bank, Bank of Jordan, British Airways, Plenum, and many others.
                  </td>
                </tr>
                <tr>
                  <td height={25} style={{padding: '10px'}}>
                    For more information about any of the above services, please contact us at
                    <a href="https://CoinsInsight.com/mf_contact.aspx?src=Contact_Partners">Click here</a>
                    or by telephone at +962 6 5690450/4.
                  </td>
                </tr>
              </tbody></table>
          </div>    
          <div className="col-sm-3" style={{width: '25.86%', paddingLeft: '0px', paddingRight: '0px', marginLeft: '20px'}}>
            {/* Square banner Num 1 */}
            <div id="RColFirtsSquareBanner" className="market-data mtt30" style={{marginTop: '0px'}}>
              <div id="adsdiv"><div>{/* log banner=1846 activeBanners=1846 country=JO   IP= */}<div id="ms-ad-719181200" /> 
                </div></div>
            </div>
            {/*End Square banner Num 1 */}
            {/* RSS Num 2 */}
           
            {/* End RSS Num 2 */}
            {/* Square banner Num 3 */}
            {/* Advertisement sidebar 03 */}
            <div id="RColSecondSquareBanner" className="advertisement mtt30">
              <br />
              <br />
              <div id="adsdiv"><div>{/* log banner=1798 activeBanners=1798 country=JO   IP= */}
                  {/* Square Fixed 2024 */}
                  <ins className="adsbygoogle" style={{display: 'inline-block', width: '300px', height: '250px'}} data-ad-client="ca-pub-1786942026589567" data-ad-slot={2397168248} />
                </div></div>
            </div>
            {/* End Square banner Num 3 */}
          </div>
          {/* //col-sm-3 */}
        </div>
      </section>
 
         </>
        );
    }


  
