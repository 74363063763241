import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';


 
 
export default function ContactUs() {

  
  

 




 
 

 

 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
 

 
 

    async function refda() {  

   
        
     
        
 


      }










 
  
  
     async function refda() {  

      
   

      setNisLoading(true);
   
      
    
       
    }
  
  
    


         

     
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )

   
           return (
          <>


 
 <div className='backgroundcolor'> 
 
        <div className='container '>
     
 
        <h3 className='mt-1 Redcolorhover col-2 p-2'>Contact Us</h3>
        <hr className='w-50 float-left hr'></hr>




       <div className='row  mt-5'>
         <div className='col-2'>
<h6 className='Redcolorhover'> Name</h6>
         </div>
         <div className='col-10'>
<input className='form-control w-50 redborder' type='text' placeholder='Name'/>
         </div>

       </div>

       <div className='row  mt-2'>
         <div className='col-2'>
<h6 className='Redcolorhover'> Company</h6>
         </div>
         <div className='col-10'>
<input className='form-control w-50 redborder' type='text' placeholder='Company'/>
         </div>

       </div>


       <div className='row  mt-2'>
         <div className='col-2'>
<h6 className='Redcolorhover'> Country</h6>
         </div>
         <div className='col-10'>
<select className='form-control w-50 redborder' >
    <option>
    --Select Country--
    </option>
    <option>
       2
    </option>
    <option>
      3
    </option>
    </select>
         </div>

       </div>

       <div className='row  mt-2'>
         <div className='col-2'>
<h6 className='Redcolorhover'> Website</h6>
         </div>
         <div className='col-10'>
<input className='form-control w-50 redborder' type='text' placeholder='Website'/>
         </div>

       </div>

       <div className='row  mt-2'>
         <div className='col-2'>
<h6 className='Redcolorhover'> Phone</h6>
         </div>
         <div className='col-10'>
<input className='form-control w-50 redborder' type='text' placeholder='Phone'/>
         </div>

       </div>

       <div className='row  mt-2'>
         <div className='col-2'>
<h6 className='Redcolorhover'> Body</h6>
         </div>
         <div className='col-10'>
<textarea className='form-control w-50 redborder' placeholder='Body'></textarea>
         </div>

       </div>


       
       <div className='row  mt-2'>
         <div className='col-2'>
<h6 className='Redcolorhover'> Email</h6>
         </div>
         <div className='col-10'>
<input className='form-control w-50 redborder' type='email' placeholder='Email'/>
         </div>

       </div>




       <div className='row  mt-2'>
         <div className='col-2'>
<h6 className='Redcolorhover'> Subject</h6>
         </div>
         <div className='col-10'>
<select className='form-control w-50 redborder' >
    <option>
    --Select Subject--
    </option>
    <option>
       2
    </option>
    <option>
      3
    </option>
    </select>
         </div>

       </div>


       <div className='row  mt-4 '>
       
         <div className='col-12  ml-5 '>
<button className='form-control w-25  marginleft10 redborder'>Submit</button>

         </div>
 

       </div>








        </div>
 {/* end main container */}
 
 </div>
         </>
        );
    }


  
