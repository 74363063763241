import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
 
import axios, { Axios } from 'axios';
import parse from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import {variables} from './Variables.js';
import { FALSE } from 'sass';

import MostViewed from './MostViwed.js';
 
 
export default function EntertaimentList() {

  const jsonUrl = variables.basename_url +'updates/index/Coinsinsight_HomeEn_Crypto.json';
 
  
 
    const [isLoading,setNisLoading] = useState(false);
    const navigate = useNavigate();
    const usenavigate=useNavigate();
     
 
  
    async function refda() {  
                 //  To Show or Hidden Header And Footer
                 document.getElementById('English_Header').hidden = false;
                 document.getElementById('English_Footer').hidden = false;
                 //
      setNisLoading(true);
   
	 



    fetch( variables.API_URL+ '/external_site/meta_Domain?Domain=' + variables.domain + '&FullDomain=' + variables.basename_url   ,{
        method:'GET',
        headers:{'content-type':'application/json'},
        
    }).then((res) => {
        return res.json();
    }).then((resp) => {
        
        
        
        
       
    }).catch((err) => {
        // alert('  Failed  ');
    });


      Get_Data();
     
    }

    
      const [JsonArray, setJsonArray] = React.useState([]);
 

      async function Get_Data(){
     
        JsonArray.splice(0);  //to clear All data in your page
         

       setNisLoading(true);
    
       axios.get(jsonUrl)  

             .then(function (response) {
             setNisLoading( false);

  
             for (let i in response.data) {
             
              JsonArray.push({
            
                news_title: response.data[i].news_title,
                news_date:response.data[i].news_date.split('T')[0],
                news_imagename:variables.ImageStart + response.data[i].news_imagename  ,
                link:response.data[i].link,
                Description:response.data[i].Description.replace("https://menafn.com/",variables.domain_name + "/") .replace("https://menafn.com",variables.domain_name  ) .replace("MENAFN",variables.domain) .replace("MENAFN",variables.domain)  ,

               Category:response.data[i].Category,
              })
              }
        })      
       }
         
    
     
             
       
         
      
   
   
      useEffect(() => {
       
       
        setNisLoading(true);
   
        refda();
           },[] )

 

            return (
          <>

 
                  <div className="col-lg-6 col-md-6 p-3">
                  {JsonArray.map(da=>     
  
  <div key= {da.news_title}  > 
                    <div className="trending-news-item mb-30">
                      <div className="trending-news-thumb">
                        <a href={da.link}>
                        <img className='rounded' style={{ minWidth:'350px', maxWidth: '350px', maxHeight: '250px', minHeight: '250px' }} src={da.news_imagename} alt="trending" />
                        </a>
                        <div className="circle-bar">
                          <div className="first circle"><canvas width={40} height={40} />
                            
                          </div>
                        </div>
                      </div>
                      <div className="trending-news-content">
                        <div className="post-meta">
                          <div className="meta-categories">
                            <a >{da.Category}</a>
                          </div>
                          <div className="meta-date">
                            <span>{da.news_date}</span>
                          </div>
                        </div>
                        <h3 className="title"><a href={da.link}>{da.news_title}</a></h3>
                        <p className="text">{da.Description}</p>
                      </div>
                    </div>
                    </div>
  )}
                  </div>

            
   
         </>
        );
    }


  
